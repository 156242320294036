import React from "react";

const Footer = () => {
    return (
        <div>
            <footer className="page-footer text-center font-small mt-4 wow fadeIn">
                <div className="container">
                    <hr className="my-4"/>

                    <div className="pb-4">
                        <a className="mr-3" href="https://www.facebook.com/ShahusGardenHighSchoolandJrCollege/"
                           target="_blank">
                            <i className="fa fa-facebook-square"/>
                            acebook
                        </a>
                        <a href="#" className="mr-3">
                            <i className="fa fa-envelope"/> shahugardenconvent@gmail.com
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 px-4">
                            <h5 className="feature-title">Shahu's Garden High School and Jr College</h5>
                            <p className="grey-text text-justify">We at Shahu's Garden High School and Jr College
                                Group understand the Student's Need of Conceptual Clarity
                                of a Subject for which excellent exposure to Science Practicals in a Well Equipped
                                Science
                                Laboratories is required.
                                <br/><br/>
                                We at Shahu's Garden High School and Jr College Group take utmost care of this
                                requirement
                                of Students by providing them very good exposure to Science Practicals in our State
                                of the Art
                                Science Laboratories under the guidance of our able, highly experienced, expert and
                                dedicated Team
                                of Faculties.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-12 px-4 text-justify">
                            <div>
                                <strong><u>MAIN BRANCH:</u><br/>Address:</strong><span className="grey-text"> Shahu Garden High School, Mahatma Fuley Nagar, Manewada Road, Nagpur - 440027</span><br/>
                                <strong>Contact: </strong><span
                                className="grey-text"> 0712-2751151, 8446655522</span>
                            </div>
                            <br/>
                            <div>
                                <strong><u>BRANCH:</u><br/>Address:</strong><span className="grey-text"> Shahu
                                    Nagar, Manewada-Besa Road, Nagpur - 440034</span><br/>
                                <strong>Contact: </strong><span className="grey-text">8484477101, 8446055522</span>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 px-4">
                            <img src="img/logo/certificate.jpg" style={{width: "80%", height: "99%"}} alt=""/>
                        </div>

                    </div>

                </div>
                <div className="footer-copyright py-3">
                    Copyright © 2023. All Rights Reserved.
                </div>
            </footer>
        </div>
    );
}

export default Footer;
