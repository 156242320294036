import React, { Component } from 'react'

export default class Banner extends Component {
    getBannerImage = (img) => {
        const bannerImgClass = `carousel-item ${img.active ? 'active' : ''}`;
        return (
            <div className={bannerImgClass}>
                <div className="view">
                    <img className="d-block w-100" src={img.src}
                        alt="First slide" />
                </div>
                <div className="carousel-caption">
                    <h3 className="h3-responsive">{img.mainCaption}</h3>
                    <p>{img.caption}</p>
                </div>
            </div>
        );
    }
    render() {
        return (
            <div>
                <div id="banner" className="carousel slide carousel-fade" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#banner" data-slide-to="0" className="active"></li>
                        {/* <li data-target="#banner" data-slide-to="1"></li>
                        <li data-target="#banner" data-slide-to="2"></li> */}
                    </ol>
                    <div className="carousel-inner" role="listbox">
                        {this.getBannerImage({ src: "/img/carousel/3.jpg", active: true })}
                        {/* {this.getBannerImage({ src: "/img/carousel/3.jpg" })}
                        {this.getBannerImage({ src: "/img/carousel/3.jpg" })} */}
                    </div>
                    <a className="carousel-control-prev" href="#banner" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#banner" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        )
    }
}
