import React from 'react';
import Card from "./views/card";

const Admission = () => {
    const admissionGoogleFormUrl = "https://forms.gle/5N4ft5hXsbHHBiJG7";
    return (
        <div>
            <Card>
                <div className="card-body">
                    <div className="text-justify container">
                        <a href={admissionGoogleFormUrl}>
                            <div className="text-center">
                                <button className="btn btn-red waves-effect waves-light">Register for Admission</button>
                            </div>
                        </a>
                        <div className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <h2 className="font-weight-bold h2 dark-grey-text text-center m-5">AIM OF THE
                                INSTITUTION</h2>
                        </div>
                        <ul className="aim">
                            <li>The School aims at accepting the best that modern research in education has to offer and
                                spreading
                                knowledge with the avowed goal of imparting social and cultural guidance to all sections
                                of
                                society.
                                In the process of carrying out this the integrated programme, we allow our children to
                                interact
                                creatively, constructively and comfortably with their environment.
                            </li>

                            <li> We know that every child is a unique human being with his/her own hopes and talents.
                                Hence
                                our
                                special endeavor is to bring out the unique personality trait in each child and allow it
                                to
                                grow
                                till its full potential is realized.
                            </li>

                            <li> The students are assisted to grow morally, intellectually, physically and socially by
                                forming in
                                them habits of basic virtues like honesty, dedication, hard work, excellence in all
                                activities,
                                habits of health care, concern for others and by the cultivation of cultural and
                                aesthetic
                                value
                                characteristic to our ancient heritage.
                            </li>

                            Hence, value education is our main MOTTO!

                            <li>cases will be brought into notice to parents. Necessary steps will be taken for the
                                dismissal if the
                                School Management finds the child is inappropriate for our school.</li>

                            <li> At the beginning of the school session, each child will purchase a school diary along
                                with
                                his/her
                                textbooks. The child should bring the diary daily to school. Parents should check the
                                diary
                                every
                                day without fail for school notices, list of holidays and any other necessary
                                communication
                                made by
                                the teacher.
                            </li>

                            <li>All other rules and regulations are mentioned in a detailed manner in the diary so
                                please
                                read
                                it.
                            </li>
                        </ul>
                    </div>
                    <div className="container">
                        <div className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>
                            <h2 className="font-weight-bold h2 dark-grey-text text-center m-5">PROMINENT FEATURES</h2>
                        </div>

                        <ol className="prominent-features">
                            <li>Highly qualified, dedicated, efficient and affectionate team of teachers.</li>

                            <li>Knowledge bank i.e. peaceful and enchanting library.</li>

                            <li>Educational films and slide shows.</li>

                            <li>Computers with multi-media and colour monitor which adds changing trends in education.
                            </li>

                            <li>Play way and activity approach towards education.</li>

                            <li>Regularly organised personality development programme.</li>

                            <li>Well maintained personal file of every student.</li>

                            <li>Regular communication with parents/guardians to

                                discuss the children's progress and conduct.
                            </li>

                            <li>Special training in discipline and good habits.</li>

                            <li>Educational and amusing toys.</li>

                            <li> Cultural programme and music.</li>

                            <li>Modern playing equipment.</li>

                            <li> Vast playground with natural greenery and

                                garden.
                            </li>

                            <li>Colourful and well decorated wooden furniture and

                                Class Rooms.
                            </li>

                            <li> Studies through E-learning.</li>
                        </ol>
                    </div>
                    <section id="contact-us">

                        <div className="card-body">
                            <form name="">
                                <div className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>
                                    <h2 className="font-weight-bold h1 dark-grey-text text-center mb-5">Enquiry
                                        Form</h2>
                                </div>
                                <hr/>

                                <div className="md-form">
                                    <i className="fa fa-user prefix grey-text"/>
                                    <input type="text" id="name" className="form-control" required=""/>
                                    <label htmlFor="form3">Your name</label>
                                </div>
                                <div className="md-form">
                                    <i className="fa fa-address-card prefix grey-text"/>
                                    <input type="text" id="email" className="form-control" required=""/>
                                    <label htmlFor="form2">Your address</label>
                                </div>
                                <div className="md-form">
                                    <i className="fa fa-envelope prefix grey-text"/>
                                    <input type="email" id="email" className="form-control" required=""/>
                                    <label htmlFor="form2">Your email</label>
                                </div>

                                <div className="md-form">
                                    <i className="fa fa-phone prefix grey-text"/>
                                    <input type="text" id="phone" className="form-control" required=""/>
                                    <label htmlFor="form2">Your Phone no</label>
                                </div>

                                <div className="md-form">
                                    <i className="fa fa-pencil prefix grey-text"/>
                                    <textarea type="text" id="message" className="md-textarea"
                                              required=""/>
                                    <label htmlFor="form8">Your Query</label>
                                </div>

                                <div className="text-center">
                                    <button className="btn btn-red waves-effect waves-light">Submit</button>
                                </div>

                            </form>

                        </div>

                    </section>
                </div>
            </Card>
        </div>
    );
};

export default Admission;