import React from 'react';

const FacililtyCard = (props) => {
    return (
        <div>
            <hr className="mb-5"/>

            <div className="row mt-3 wow fadeIn">

                <div className="col-lg-5 col-xl-4 mb-4">
                    <div className="view overlay rounded z-depth-1">
                        <img
                            src={props.src}
                            className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="col-lg-7 col-xl-7 ml-xl-4 mb-4">
                    <h3 className="mb-3 font-weight-bold dark-grey-text">
                        <strong>{props.header}</strong>
                    </h3>
                    <p className="grey-text">{props.desc}</p>
                </div>

            </div>
        </div>
    );
};

export default FacililtyCard;