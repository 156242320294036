import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import About from "./about";
import "./App.css";
import Footer from "./Footer/footer";
import Home from "./Home";
import {Navbar} from "./Navbar";
import Faculty from "./faculty";
import Admission from "./admission";
import Contact from "./contact";
import Gallery from "./gallery/gallery";
import Facility from "./Facility/facility";

const App = () => {
    return (
        <Router>
            <Navbar/>

            <Switch>
                <Route path="/about">
                    <About/>
                </Route>
                <Route path="/faculty">
                    <Faculty/>
                </Route>
                <Route path="/gallery">
                    <Gallery/>
                </Route>
                <Route path="/facilities">
                    <Facility/>
                </Route>
                <Route path="/admission">
                    <Admission/>
                </Route>
                <Route path="/contact">
                    <Contact/>
                </Route>
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
            <Footer/>
        </Router>
    );
};

export default App;
