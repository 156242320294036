import React from 'react';
import Card from "../views/card";
import {GalleryApp} from "./galleryApp";

const Gallery = () => {
    return (
        <div>
            <Card>
                <div className="wow fadeIn">
                    <h2 className="font-weight-bold h1 dark-grey-text text-center mb-5">Gallery</h2>
                </div>
                <GalleryApp/>
            </Card>
        </div>
    );
};

export default Gallery;