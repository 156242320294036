import React from "react";
import Banner from "./Banner/banner";
import FacilitySection from "./facilitySection";
import Modal from "./views/modal";

const Home = () => {
  return (
    <>
      <header>
        <Banner />
      </header>
      <main>
        <div className="card container">
          <div className="card-body">
            <div className="container">
              <h3 className="h2 text-center mb-5">
                <strong> Our Inspiration</strong>
              </h3>
              <p className="text-justify">
                <strong>
                  We envision a robust, vibrant and holistic school education
                  that will engender excellence in every sphere of human
                  endeavour. The "Shahu's Garden High School and Jr College" is
                  committed to promote intellectual, social and cultural
                  vivacity among it’s learners. It works toward evolving a
                  learning process and environment, which empowers the future
                  citizens to become global leader In the world.
                </strong>
              </p>
              <hr className="my-5" />
              <FacilitySection />
            </div>
          </div>
        </div>
        {/* <Modal/> */}
      </main>
    </>
  );
};

export default Home;
