import React from 'react';
import {Link} from "react-router-dom";

export const Navbar = () => {
    const navLinks = [
        {
            name: "Home",
            url: "/"
        },
        {
            name: "About",
            url: "/about"
        },
        {
            name: "Faculty",
            url: "/faculty"
        },
        {
            name: "Gallery",
            url: "/gallery"
        },
        {
            name: "Facilities",
            url: "/facilities"
        },
        {
            name: "Admission",
            url: "/admission"
        },
        {
            name: "Contact Us",
            url: "/contact"
        },
    ];
    return (
        <div>
            <div className="d-none d-sm-block text-center" style={{ backgroundColor: "#fff", padding: "0px 15px" }}>
                <img src="./img/logo/logo.png" alt="" style={{ height: "80px" }}/>
                <img src="./img/logo/logo1.png" alt="" style={{ height: "80px" }} />
            </div>
            <nav className="navbar navbar-expand-sm navbar-dark">
                <div className="container-fluid">

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul className="navbar-nav mr-auto">
                            {
                                navLinks.map((link, i) => {
                                    return (
                                        <li key={i} className="nav-item">
                                            <Link className="nav-link" to={link.url}>{link.name}</Link>
                                        </li>);
                                })
                            }
                        </ul>

                        <ul className="navbar-nav nav-flex-icons">
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fa fa-envelope"/> shahugardenconvent@gmail.com</a>
                            </li>
                        </ul>

                    </div>

                </div>
            </nav>
        </div>
    );
}
