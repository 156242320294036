import React from 'react';
import Card from "../views/card";
import FacililtyCard from "./facililtyCard";

const Facility = () => {
    const facilities = [
        {
            src: 'img/gallery/New folder (2)/(3).jpg',
            header: 'INFRASTRUCTURE',
            desc: `Our school has ventilated, spacious and well designed classroom, sports
                                playground, staff rooms, Labs and Admin area for all levels of students.`
        },
        {
            src: 'img/gallery/New folder (2)/(9).jpg',
            header: 'ACADEMICS',
            desc: `Provide a well-rounded program of instruction using the best
                                educational
                                practices appropriate to
                                the cultural, intellectual and social needs of the students`
        },
        {
            src: 'img/facilities/mission.jpg',
            header: 'VISION & MISSION',
            desc: `We envision a robust, vibrant and holistic school education that will
                                engender
                                excellence in every
                                sphere of human endeavor. To pursue excellence and set the pace in the field`
        },
        {
            src: 'img/facilities/technology.jpg',
            header: 'TECHNOLOGY',
            desc: `The state-of-the-art computer center provides plenty of opportunities
                                for
                                students to get exposure to the IT environment and learn to use various tools and
                                software in
                                their daily life and syllabus.`
        },
        {
            src: 'img/gallery/(33).jpg',
            header: 'ACTIVITIES',
            desc: `Co-curricular activities facilitate the development of various domains
                                of mind and personality such as intellectual development, emotional development, social
                                development, moral development, and aesthetic development. Creativity, Enthusiasm, and
                                Energetic, Positive thinking are some of the facets of personality development and the
                                outcomes of Co-curricular activities.`
        },
        {
            src: 'img/gallery/(23).jpg',
            header: 'FACILITIES',
            desc: `Large, fully equipped laboratories are there to encourage students to
                                partially
                                explore what they
                                have learn theoretically and inculcate in them the scientific experimentation`
        },
    ];

    return (
        <div>
            <Card>
                <div className="wow fadeIn">
                    <h2 className="font-weight-bold h1 dark-grey-text text-center mb-5">Facilities</h2>
                </div>
                {
                    facilities.map(facility => <FacililtyCard {...facility}/>)
                }
            </Card>
        </div>
    );
};

export default Facility;