import React from 'react';
import Card from "./views/card";

const Faculty = () => {
    return (
        <div>
            <Card>
                <section>
                    <div className="wow fadeIn">
                        <h2 className="font-weight-bold h1 dark-grey-text text-center">Faculty</h2>
                    </div>


                    <hr className="mb-5"/>

                    <div className="row mt-3 wow fadeIn">

                        <div className="col-lg-5 col-xl-4 mb-4">
                            <div className="view overlay rounded z-depth-1">
                                <img src="img/faculty/director.jpg" className="img-fluid" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-7 ml-xl-4 mb-4">
                            <h3 className="mb-3 font-weight-bold dark-grey-text">
                                <strong>Director - Mr. Umesh Shahu</strong>
                            </h3>
                            <p className="grey-text text-justify">Hearty Greeting from Shahu garden
                                School &amp; Junior
                                College, Nagpur!
                                We all are aware that education is reckoned as a mighty instruction of National
                                Development and is certainly availed of to augment knowledge and enrich culture.
                                Therefore, proper education is essential to draw out the best in every pupil to make
                                them stand apart.
                                To ensure the same our institution delivers best-in-class education by adhering to
                                the highest academic standards. Our teaching staff members are highly inspiring and
                                innovative and are capable of facilitating excellent performance by the
                                students.</p>
                        </div>

                    </div>

                    <hr className="mb-5"/>

                    <div className="row mt-3 wow fadeIn">

                        <div className="col-lg-5 col-xl-4 mb-4">
                            <div className="view overlay rounded z-depth-1">
                                <img src="img/faculty/principal.jpg" className="img-fluid" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-7 ml-xl-4 mb-4">
                            <h3 className="mb-3 font-weight-bold dark-grey-text">
                                <strong>Principal - Mrs. Sushma Shahu</strong>
                            </h3>
                            <p className="grey-text text-justify">Dear parents, Shahu garden School is an
                                amalgamation of
                                competent teachers,
                                state-of- the-art infrastructure, an idyllic setting and an experienced and
                                efficient administration. The school came into existence with the vision of
                                creating a safe and supportive environment for its students and to provide a
                                perfect balance of academics, sports, artistic and social opportunities. Modern
                                and secure infrastructure, pollution free environment, excellent pastoral care
                                is
                                some of our strengths. While studying at Shahu garden School your child will be
                                groomed to become a confident, articulate and enlightened gentleman, ready to
                                step into global society with dignity, honor and integrity. I encourage you to
                                come and visit our campus and experience the warmth and enthusiasm towards
                                life that is infused in our school’s ambience.</p>
                        </div>

                    </div>

                    <hr className="mb-5"/>

                    <div className="text-center">
                        <h3 className="mb-3 font-weight-bold dark-grey-text">
                            <strong>Our Staff</strong>
                        </h3>
                        <div className="view overlay rounded z-depth-1">
                            <img src="img/faculty/staff.jpg" className="img-fluid" alt=""/>
                            <img src="img/faculty/staff2.jpg" className="img-fluid" alt=""/>
                        </div>
                    </div>

                </section>
            </Card>
        </div>
    );
};

export default Faculty;