import React from "react";

const FacilitySection = () => {
    const cards = [
        {
            imgSrc: "img/gallery/New folder (2)/(3).jpg",
            title: "INFRASTRUCTURE",
            desc: `The Shahu garden school has ventilated, spacious and well designed classNameroom,
                        staff rooms,
                        Labs and Admin area.`
        },
        {
            imgSrc: "img/gallery/New folder (2)/(9).jpg",
            title: "ACADEMICS",
            desc: `Provide a well-rounded program of instruction using the best educational practices appropriate to the cultural, intellectual and social needs of the students`
        },
        {
            imgSrc: "img/facilities/mission.jpg",
            title: "VISION & MISSION",
            desc: `We envision a robust, vibrant and holistic school education that will engender excellence in every sphere of human endeavor. To pursue excellence and set the pace in the field`
        },
        {
            imgSrc: "img/facilities/technology.jpg",
            title: "TECHNOLOGY",
            desc: `The state-of-the-art computer center provides plenty of opportunities for
                        students to get exposure to the IT environment and learn to use various tools and software in
                        their daily life and syllabus.`
        },
        {
            imgSrc: "img/gallery/(33).jpg",
            title: "ACTIVITIES",
            desc: `Co-curricular activities facilitate the development of various domains of
                        mind and personality such as intellectual development, emotional development, social
                        development, moral development, and aesthetic development. Creativity, Enthusiasm, and
                        Energetic, Positive thinking are some of the facets of personality development and the outcomes
                        of Co-curricular activities.`
        },
        {
            imgSrc: "img/gallery/(23).jpg",
            title: "FACILITIES",
            desc: `Large, fully equipped laboratories are there to encourage students to
                          partially
                          explore what they
                          have learn theoretically and inculcate in them the scientific experimentation`
        }
    ];
    const getCard = card => {
        return (
            <div className="card" style={{width: "340px"}}>
                <img
                    style={{height: "180px"}}
                    src={card.imgSrc}
                    className="card-img-top"
                    alt=""
                />

                <div className="card-body">
                    <h4 className="card-title">{card.title}</h4>
                    <p className="card-text">{card.desc}</p>
                </div>
            </div>
        );
    };


    return (
        <div
            className=""
            style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gridGap: '20px'
            }}
        >
            {cards.map(card => {
                return getCard(card);
            })}
        </div>
    );

}

export default FacilitySection;
