import React from 'react';
import Card from "./views/card";
import MapView from "./views/map";

const Contact = () => {
    return (
        <div>
            <Card>
                <section id="contact-us">

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="col-lg-6 col-md-12 px-4">
                                <strong><u>MAIN BRANCH:</u> <br></br>Address:</strong><span className="grey-text">
                                    Shahu Garden High School, Mahatma Fuley Nagar, Manewada Road, Nagpur - 440027</span>
                                <br/>
                                <strong>Contact: </strong><span
                                className="grey-text"> 0712-2751151, 8446655522</span>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="view overlay rounded z-depth-1" style={{height: "300px"}}>
                                        <img src="img/building/1.jpg" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <MapView
                                        position={[21.1085679, 79.1032908]}
                                        message={"Shahu's Garden School and Jr College"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="col-lg-6 col-md-12 px-4">
                                <br/>
                                <strong><u>BRANCH:</u> <br></br>Address:</strong><span className="grey-text"> Shahu
                                    Nagar, Manewada-Besa Road, Nagpur - 440034</span>
                                <br/>
                                <strong>Contact: </strong><span className="grey-text"> 8484477101, 8446055522</span>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="view overlay rounded z-depth-1" style={{height: "300px"}}>
                                        <img src="img/building/2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <MapView
                                        position={[21.095785, 79.101070]}
                                        message={"Shahu's Garden School and Jr College"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Card>
        </div>
    );
};

export default Contact;