import React from 'react';

const Card = (props) => {
    return (
        <div className="container">
            <div className="card" style={{margin: "25px 0px"}}>
                <div className="card-body">
                    <div className="pt-5 card-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;