import React, {Component} from "react";
import Card from "./views/card";

class About extends Component {
    render() {
        return (
            <div>
                <Card>
                    <section>
                        <div className="wow fadeIn">
                            <h2 className="font-weight-bold h1 dark-grey-text text-center mb-5">About Us</h2>
                        </div>
                        <h3 className="h3 text-center mb-5"/>

                        <div
                            className="row wow fadeIn"
                            style={{visibility: "visible", animationName: "fadeIn"}}
                        >
                            <div className="col-lg-12 col-md-12 px-4">
                                <div className="row">
                                    <div className="col-1 mr-3"/>
                                    <div className="col-10">
                                        <div
                                            className="wow fadeIn"
                                            style={{
                                                visibility: "visible",
                                                animationName: "fadeIn"
                                            }}
                                        >
                                        </div>
                                        <p className="grey-text text-justify">
                                            "Shahu Garden Group" of education after getting a
                                            grand success in social and educational fields entered
                                            into "C.B.S.E. Pattern" to cater to the needs of South
                                            Nagpur. We provide effective education to make student
                                            strive for excellence in both academic and
                                            non-academic activities and enable all-round
                                            development of the child. Our aim is to develop in
                                            students self-confidence, self-discipline, knowledge,
                                            and skills for grooming. Shahu's Garden Convent, an
                                            English Medium co-educational school established by
                                            the "Vidya Vikas Adarsh Sanstha" is comprising of
                                            Pre-Primary, Primary, Middle, High School and Junior
                                            College. "Vidwan Sarvatra Pujjyate", the School is
                                            recognized by the Govt. of Maharashtra, Department of
                                            Education, follows the Secondary School Education.
                                            Curriculum. The School has an aesthetically beautiful
                                            building situated in a vast open area covered by
                                            greenery, well accomplished by modern playing and
                                            entertaining equipment's for the children, in a
                                            peaceful environment. Our school is also possessing
                                            "FUNSKOOL" (Playgroup) which is especially for
                                            pre-nursery kids.
                                        </p>

                                        <h3 className="feature-title">Vision</h3>
                                        <p className="grey-text text-justify">
                                            Dear Parents, The School aims at spreading the best
                                            aimed modern research in education helps in spreading
                                            knowledge with the aimed goal of imparting social and
                                            cultural guidance to all sections of the society. In
                                            the process of carrying out this integrated programme,
                                            we allow our children to interact creatively,
                                            constructively and comfortably in the environment. We
                                            know that every child is a unique human being with his
                                            / her own hopes and talents. Hence our special
                                            endeavor is to bring out the unique personality trait
                                            in each child and allow it to grow till its full
                                            potential is realized. The students are assisted to
                                            grow morally, intellectually, physically and socially
                                            by forming in them habits of basic virtues like
                                            honesty, dedication, hard work, excellence in all
                                            activities, habits of health care, respect &amp;
                                            concern for others by the cultivation of cultural and
                                            moral value characteristic to our ancient heritage.
                                        </p>
                                        <h3 className="feature-title">Mission</h3>
                                        <p className="grey-text text-justify">
                                            To provide a wide array of Academic &amp; Non-academic
                                            programme that assure core competencies and Nurture
                                            the unique talents of individuals that they are
                                            regularly revised to meet current needs and anticipate
                                            challenges. This aims for preparing responsible
                                            citizens, sound character, life long learning and a
                                            productive Individual.
                                        </p>
                                    </div>
                                </div>

                                <div style={{height: "30px"}}/>

                                <div style={{height: "30px"}}/>
                            </div>
                        </div>
                    </section>
                </Card>
            </div>
        );
    }
}

export default About;
