import React from 'react'
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';

const MapView = (props) => (
    <Map center={props.position} zoom={15} attributionControl={false}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution=""
        />
        <Marker position={props.position}>
            <Popup>{props.message}</Popup>
        </Marker>
    </Map>
)

export default MapView;